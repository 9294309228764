import React, { useState } from 'react'
import styled from 'styled-components'

import { Container, P } from "../../util/standard"
import { icons, MOBILE_BREAKPOINT, TABLET_BREAKPOINT, SMALLER_MOBILE } from '../../util/constants'
import { useEffect } from 'react'
import { navigate } from 'gatsby'

interface Props {
  heroStyling?: boolean;
  id: string;
}


const NewsLetterSignUp = styled(Container) <{ heroStyling?: boolean; }>`
 flex-wrap: wrap;
 height: 70px;
 width: 100%;

 ${({ heroStyling }) => heroStyling && `
  width: 30%;
  min-width: 550px;
  margin: 50px auto 0px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    min-width: 0px;
  }
 `}

 @media (max-width:${MOBILE_BREAKPOINT}px){
    grid-column-start: 1;
    grid-column-end: 4;
    margin-top: 50px;
  }
`

const SignUpP = styled(P) <{ heroStyling?: boolean; }>`
 width: 100%;
 height: 30px;
 margin: 0px;
 color: white;

 ${({ heroStyling }) => heroStyling && `
    width: 30%;
  height: 30px;
  display: inline-grid;
  align-content: center;
  margin:5px 30px 5px 5px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 60%;
    margin: 0 auto;
    display:block;
  }
 `}
`

const EmailTextInput = styled.input`
background: border-box;
outline: none;
color: white;
text-decoration: none;
border: none;
width: 86%;

::placeholder {
  color: white;
  font-family:'light';
}

`
const EmailForm = styled(Container) <{ heroStyling?: boolean; }>`
width: 90%;
border-bottom: 1px solid white;

 ${({ heroStyling }) => heroStyling && `
  width: 60%;
  height: 30px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto;
  }

   @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 auto;
  }
  
  `}
`

const SubmitIcon = styled.img<{ heroStyling?: boolean; hoverEffect: boolean; }>`
width: 30%;
margin-left: 60%;
  ${({ hoverEffect }) => hoverEffect && `transform: translateX(10px);`};

 ${({ heroStyling }) => heroStyling && `
  width: 20%;
  margin-left: 80%;
 `}

 transition: transform 0.2s;
`

const FormWrapper = styled.form<{ heroStyling: boolean; }>`
  display: flex;
  flex-wrap: wrap;

  ${({ heroStyling }) => heroStyling && `
  width: 100%;
  margin: 0 auto;
  `}
`

const SubmitButton = styled.button`
width: 70%;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
`

const Label = styled.label`
position: absolute;
opacity: 0;
`

export default function EmailInput(props: Props) {

  const [hoverEffectOn, toggleHoverEffectOn] = useState(false)
  const [email, toggleEmail] = useState("")
  const [formSubmitted, toggleFormSubmitted] = useState(false)
  const [pageLocation, updatePageLocation] = useState('')

  const { submitArrow } = icons
  const { heroStyling, id } = props

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'newsletter', email })
    }

    fetch(
      "/",
      options
    )
      .then(function (response) {
        alert('success"')
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    updatePageLocation(window.location.pathname)
  })

  return (
    <NewsLetterSignUp heroStyling={heroStyling}>
      {!formSubmitted &&
        <FormWrapper name='newsletter' data-netlify="true" method="POST" heroStyling={heroStyling}>
          <input name="form-name" type="hidden" />
          <SignUpP heroStyling={heroStyling}>Sign up to our Newsletter</SignUpP>
          <EmailForm onMouseEnter={() => toggleHoverEffectOn(true)} onMouseLeave={() => toggleHoverEffectOn(false)} heroStyling={heroStyling}>
            <Label htmlFor={id}></Label>
            <EmailTextInput name="email" type="email" fontFamily='light' placeholder="Email Address" onChange={e => toggleEmail(e.target.value)} id={id} />
            <SubmitButton onSubmit={handleSubmit}><SubmitIcon hoverEffect={hoverEffectOn} src={submitArrow} heroStyling={heroStyling} alt='email-sign-up-submit-button-icon' /></SubmitButton>
          </EmailForm>
        </FormWrapper>
      }
      {formSubmitted && <P>Thank you for signing up!</P>}
    </NewsLetterSignUp>
  )
}

