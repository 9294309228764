import React, { useState, useEffect } from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";
import { Carousel } from "antd";

import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  icons,
  TWENTY_INCH_SCREEN,
  LAPTOP_BREAKPOINT,
} from "../../util/constants";
import { P, H1, TextButton } from "../../util/standard";
import EmailInput from "./EmailInput";
import BackgroundTexture from "./sub/BackgroundTexture";
import DonateButton from "./sub/DonateButton";

interface Props {
  isHomeHero?: boolean;
  heroText: string;
  heroBlockContent?: Array<Object>;
  heroImage: FluidObject;
  heroIcon?: FluidObject;
  blockVisible?: boolean;
  newsLetterVisible?: boolean;
  blockTextOverwrite?: string;
  whiteText?: boolean;
}

const StyledContainer = styled.div<{ homeHero?: boolean }>`
  height: 550px;
  position: relative;
  ${({ homeHero }) =>
    homeHero &&
    `
  height: 100vh;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 586px;
  }
`}
`;
const ContentContainer = styled.div<{ homeHero?: boolean }>`
  position: relative;
  ${({ homeHero }) =>
    homeHero &&
    `
  margin: auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      height: 586px;
  }
  `}
`;
const BannerContent = styled.div<{ homeHero?: boolean; animation: boolean }>`
  height: 550px;
  display: inline-grid;
  align-content: center;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  ${({ homeHero, animation }) =>
    homeHero &&
    `
  height: 100vh;

  p {
    font-size: 40px;
    color: white;
    font-family: 'light';
    width: 19%;
    margin: 0 auto;
    text-align: center;
    min-width: 320px;
  }

  strong {
    font-family: 'extraBold';
  }
@media (max-width:${MOBILE_BREAKPOINT}px){
  height: 586px;
}

h1 {
  color: white;
  width: 330px;
  top: 50%;
  text-align: center;
  margin: auto;
  font-size: 40px;
  line-height:48px;
}

@media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
  h1 {
  font-size: 40px;
  line-height:48px;
  }
}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h1 {
  font-size: 40px;
  line-height:48px;
  }
}
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h1 {
   font-size: 40px;
   line-height:48px;
  }
}
`}
`;

const GreyOverlay = styled.div`
  background-color: black;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
`;

const ArrowIMG = styled.div<{ homeHero?: boolean }>`
  ${({ homeHero }) =>
    homeHero &&
    `
  position: absolute;
  width: 100%;
  bottom: 40px;
`}
`;

const CTAIcon = styled.img<{ homeHero?: boolean }>`
  display: none;
  ${({ homeHero }) =>
    homeHero &&
    `
  display: block;
  margin: 400px auto auto auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 280px auto auto auto;
  }

  &:hover {
    cursor: pointer;
  }
`}
`;

const StyledImg = styled.img<{ homeHero?: boolean }>`
  display: none;
  ${({ homeHero }) =>
    homeHero &&
    `
 display: block;
width: 56px;
margin: 0px auto 20px auto;
`}
`;

const StyledBackgroundImage = styled(BackgroundImage)<{ homeHero?: boolean }>`
  height: 550px;
  ${({ homeHero }) =>
    homeHero &&
    `
  height: 100vh;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
       height: 586px;
  }
`}
`;

const StyledH1 = styled(H1)<{ homeHero?: boolean; isWhite?: boolean }>`
  width: 98%;
  text-align: center;
  margin: auto;
  font-size: 200px;
  line-height: 180px;
  // padding-top: 250px;
  font-weight: 900;
  opacity: ${props => (props.isWhite ? 1 : 0.1)};
  color: white;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    font-size: 180px;
    line-height: 160px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    font-size: 130px;
    line-height: 110px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 80px;
    // padding-top: 330px;
    line-height: 70px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 42px;
    width: 100%;
    line-height: 35px;
  }
  ${({ homeHero }) =>
    homeHero &&
    ` }
width: 330px;
top: 50%;
text-align: center;
margin: auto;
font-size: 40px;
line-height:48px;
opacity: 1;

@media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
font-size: 40px;
line-height:48px;
}
@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
font-size: 40px;
line-height:48px;
}
@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
 font-size: 40px;
 line-height:48px;
}
`}
`;

const Block = styled.div<{ homeHero: boolean }>`
  position: absolute;
  background-color: rgb(38 54 101);
  bottom: -4%;
  left: 10%;
  z-index: 1;

  ${({ homeHero }) =>
    homeHero &&
    `
    display: none;
`}
`;

const StyledP = styled(P)`
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 15px 30px;
  margin: 7px 0 4px 0;
`;

const SeparatedH1 = styled.h1<{ animation: boolean; index: number }>`
  opacity: 0 !important;
  ${({ index }) =>
    index &&
    `
    transform: translateX(-100%);
    transition: all ${index * 1.5}s;

  `}
  ${({ animation }) =>
    animation &&
    `
    transform: translateX(0px);
    opacity: 1 !important;
  `}
`;

const HeroContent = (props: Props) => {
  const {
    isHomeHero,
    heroText,
    heroIcon,
    blockVisible,
    newsLetterVisible,
    blockTextOverwrite,
    whiteText,
  } = props;

  const scrollOnClick = () => {
    window.scrollTo({
      behavior: "smooth",
      top: (95 / 100) * window.innerHeight,
    });
  };

  const [textAnimation, toggleTextAnimation] = useState(false);

  useEffect(() => {
    isHomeHero
      ? setTimeout(() => {
          toggleTextAnimation(true);
        }, 200)
      : null;
  }, []);
  return (
    <ContentContainer homeHero={isHomeHero}>
      <BannerContent homeHero={isHomeHero} animation={textAnimation}>
        <BackgroundTexture textureVisible />
        {isHomeHero && heroIcon && (
          <StyledImg
            homeHero={isHomeHero}
            src={heroIcon?.asset?.fluid.src}
            alt={heroIcon?.asset?.title ?? "warriors-hero-icon"}
          />
        )}
        {!isHomeHero && (
          <StyledH1 fontFamily="extraBold" homeHero={isHomeHero} isWhite>
            {heroText}
          </StyledH1>
        )}
        {/* {isHomeHero && <HeroBlockContent blocks={heroText} />} */}
        {isHomeHero &&
          heroText[0].children[0].text
            .split("\n")
            .map((textItem: string, index: number) => {
              return (
                <SeparatedH1 index={index + 1} animation={textAnimation}>
                  {textItem}
                </SeparatedH1>
              );
            })}
        {isHomeHero && (
          <DonateButton buttonUrl="#footer" buttonText="Sign Up" zIndex={3} />
        )}
        {newsLetterVisible && (
          <EmailInput heroStyling id="hero-newsletter-sign-up-prompt" />
        )}
        <ArrowIMG homeHero={isHomeHero}>
          <CTAIcon
            homeHero={isHomeHero}
            src={icons.downArrow}
            onClick={scrollOnClick}
            alt="Hero-call-to-action-button"
          />
        </ArrowIMG>
        {blockVisible && (
          <Block homeHero={isHomeHero as boolean}>
            <StyledP fontFamily="bold">
              {blockTextOverwrite ? blockTextOverwrite : heroText}
            </StyledP>
          </Block>
        )}
        <GreyOverlay></GreyOverlay>
      </BannerContent>
    </ContentContainer>
  );
};

function Hero(props: Props) {
  const { isHomeHero, heroImage } = props;

  return (
    <>
      <StyledContainer homeHero={isHomeHero}>
        {isHomeHero && (
          <Carousel autoplay>
            {heroImage.map(image => {
              if (image == null) return null;
              return (
                <StyledBackgroundImage
                  homeHero={isHomeHero}
                  fluid={image.asset?.fluid}
                  alt={image.asset?.title ?? "hero-background-image"}
                >
                  <HeroContent {...props} />
                </StyledBackgroundImage>
              );
            })}
          </Carousel>
        )}
        {!isHomeHero && (
          <StyledBackgroundImage
            homeHero={isHomeHero}
            fluid={heroImage.asset?.fluid}
            alt={heroImage.asset?.title ?? "hero-background-image"}
          >
            <HeroContent {...props} />
          </StyledBackgroundImage>
        )}
      </StyledContainer>
    </>
  );
}

export default Hero;
